a {
  text-decoration: none;
}

.mainNav {
  height: 80px;
  width: 100%;
  display: flex;
  padding: 0 20px;
  position: fixed;
  z-index: 2;
  background: transparent;
  transition: 0.3s;
}

.menuOnScroll {
  background: rgba(13, 26, 36, 0.97);
  transition: 0.3s;
}

.navLinksList {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
}

.logo {
  width: 100%;
}

.logoWrapper {
  display: flex;
  align-items: center;
  padding-right: 50px;
}

.headerSection {
  width: 100%;
  background-size: cover;
}

.navLink {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.navLinksListItem {
  padding: 10px 10px;
  margin-right: 10px;
  transition: 0.2s;
  border-radius: 5px;
}

.navLinksListItem:hover {
  background: rgba(50, 68, 77, 0.5);
  transition: 0.2s;
}

.contactButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loginIcon {
  margin-right: 5px;
}

.loginButton,
.footerLoginButton {
  color: #ffffff;
  margin-left: 15px;
  padding: 10px;
  font-size: 14px;
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  height: 45px;
  text-transform: capitalize;
  border-radius: 10px;
  width: 125px;
}

.loginButton:hover {
  color: #ffffff;
  margin-left: 15px;
  padding: 10px;
  font-size: 14px;
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  height: 45px;
  text-transform: capitalize;
  border-radius: 10px;
  background: rgba(50, 68, 77, 0.5);
}

.contactButton {
  background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
  border-radius: 6px;
  margin-left: 0;
  color: #ffffff;
  width: 125px;
}

.contactButtonWrapper {
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  border-radius: 10px;
  height: 45px;
  padding: 2px;
}

.encephalon,
.sloganGradient {
  background-image: linear-gradient(270deg, #01ffff, #09a4ed);
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headerContentWrapper {
  padding: 250px 0 100px 0;
  text-align: center;
  color: #ffffff;
}

.mainHeader {
  font-size: 50px;
}

.headerText {
  max-width: 800px;
  margin: 0 auto;
  line-height: 35px;
  font-weight: 600;
  margin-bottom: 30px;
}

.blueBg {
  color: #0f87ff;
}

.signupButton {
  background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
  text-transform: none;
  width: 200px;
  padding: 10px 0;
  color: #ffffff;
}

.partnersHeader {
  color: #99b2bf;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 25px;
  font-size: 16px;
}

.partnersWrapper {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.partnerImg {
  width: 100%;
}

.partnerImgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  flex-wrap: wrap;
  cursor: pointer;
}

.partnerImgWrapper:hover .partnerName {
  transform: translate3d(0px, -8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  transition: 0.5s;
}

.partners {
  margin-bottom: 60px;
}

.partnerName {
  color: #99b2bf;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  transform: translate3d(0px, -8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
  transition: 0.5s;
  padding-top: 12px;
}

.footer {
  width: 100%;
  padding-top: 100px;
  border-top: 1px solid #32444d;
  background-color: rgb(25, 32, 35);
}

.footerContainer {
  margin: 0 auto 100px auto;
}

.footerLogo {
  width: 150px;
}

.footerSlogan {
  color: #99b2bf;
  font-weight: 700;
  font-size: 24px;
}

.footerSlogan {
  margin-bottom: 35px;
}

.gradientLine {
  width: 100%;
  height: 2px;
  background-image: linear-gradient(270deg, #01ffff, #09a4ed);
}

.footerHeader {
  margin-bottom: 50px;
}

.footerNavContainer {
  padding-right: 40px;
}

.footerNavHeader {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 1px solid #32444d;
}

.footerNavHeaderItem {
  padding-left: 10px;
}

.footerNav {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.footerNavList {
  padding: 0;
  width: 100%;
}

.footerNavListItem {
  color: #99b2bf;
  list-style: none;
  padding: 10px 0 10px 10px;
  margin-bottom: 15px;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.2s;
  display: flex;
  align-items: center;
}

.footerNavListItem:hover {
  background-color: #32444d;
  transition: 0.2s;
}

.linkedinIcon {
  margin-right: 5px;
}

.contactCardHeader {
  margin: 0 0 15px 0;
}

.contactCard {
  padding: 20px;
  background-image: linear-gradient(220deg, #00d4d4, #0f87ff);
  box-shadow: -8px 0 32px 0 rgba(22, 152, 233, 0.2), 8px 0 32px 0 rgba(7, 200, 164, 0.2);
  border-radius: 5px;
  color: #ffffff;
  margin-bottom: 20px;
}

.contactCardText {
  color: rgba(246, 249, 250, 0.75);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
}

.contactUsButton {
  width: 100%;
  background: #ffffff;
  color: #09a4ed;
  font-weight: 700;
  letter-spacing: 1px;
  height: 45px;
}

.contactUsButton:hover {
  background-color: rgba(246, 249, 250, 0.85);
}

.footerLoginWrapper {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  align-items: center;
  font-weight: 600;
}

.footerLoginButton {
  margin-left: 0;
  width: 140px;
}

.footerLoginDivider {
  margin-bottom: 0;
}

.footerNavWrapper {
  padding-bottom: 40px;
  border-bottom: 1px solid #32444d;
  margin-bottom: 10px;
}

.copyrights {
  color: #7e929e;
  font-size: 14px;
  font-weight: 600;
}

.sidebarWrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(33, 43, 47, 0.9);
  position: fixed;
  z-index: 1;
  transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transition: 0.3s;
}

.menuIcon {
  color: #ffffff;
  display: none;
}

.showSidebar {
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transition: 0.3s;
}

.desktopScreenButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sidebarContent {
  padding: 150px 20px 100px 20px;
  color: #ffffff;
}

.sidebarMenuItem {
  padding: 20px;
  background-color: rgba(14, 21, 26, 0.95);
  background-image: radial-gradient(circle farthest-corner at 50% 0%, rgba(33, 43, 47, 0.75), hsla(0, 0%, 100%, 0));
  margin: 0 10px 20px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  display: block;
  color: #ffffff;
  height: auto;
}

.sidebarMenuItem:hover {
  background-color: rgba(33, 43, 47, 0.9);
  transition: 0.2s;
}

.sidebarMenuNumber {
  margin-bottom: 32px;
  background-image: linear-gradient(180deg, #e0e6e9, #62717a);
  opacity: 0.2;
  color: rgba(98, 113, 122, 0.25);
  font-size: 64px;
  line-height: 64px;
  font-weight: 500;
  -webkit-background-clip: text;
}

.sidebarMenuItemText {
  margin-bottom: 0px;
  color: rgba(199, 212, 218, 0.75);
  font-size: 14px;
  line-height: 20px;
}

.sidebarMenuContactItem {
  background-color: #1375d9;
  background-image: none;
  transition: 0.2s;
}

.sidebarMenuItemHeader {
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .footerLoginButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .footerLoginWrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footerLoginDivider {
    margin-bottom: 15px;
  }
}

@media (max-width: 992px) {
  .menuIcon {
    display: block;
  }

  .navLinksList {
    display: none;
  }

  .desktopScreenButtons {
    display: none;
  }
}

@media (max-width: 800px) {
  .sidebarMenuItem {
    height: 210px;
  }
}

@media (max-width: 600px) {
  .sidebarMenuNumber {
    display: none;
  }

  .sidebarMenuItem {
    padding: 10px 15px;
  }

  .sidebarMenuItemHeader {
    margin-top: 10px;
  }

  .partnersWrapper {
    width: auto;
    justify-content: space-evenly;
  }

  .sidebarMenuItem {
    height: auto;
  }
}
