.formWrapper {
  padding-top: 150px;
  padding-bottom: 150px;
  z-index: 0;
}

.wrapper {
  background: #000000;
}

.formHeader {
  color: #ffffff;
  margin-bottom: 40px;
}

.form {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(33, 43, 47, 0.8);
  border-top: 1px solid #32444d;
  border-radius: 8px;
}

.formControl {
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

.confirmButton {
  width: 100%;
  border-radius: 12px;
  background-color: #32444d;
  color: #f1f4f6;
  height: 40px;
  text-transform: capitalize;
}

.validationMessage {
  height: 20px;
  padding-top: 5px;
  color: #a70e0e;
}

.progressIcon {
  color: #f1f4f6;
}

.createAccountText {
  color: #7e929e;
  text-align: center;
}

.createAccountLink {
  color: #05af90;
}

.linkToLogin {
  color: #ffffff;
  text-align: center;
}

.loginLink {
  color: #05af90;
}

.inputLabel {
  color: rgba(255, 255, 255, 0.7);;
  text-align: left;
  font-size: 12px;
  margin-bottom: 5px;
  padding-left: 5px;
}
