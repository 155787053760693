.downloadButtonWrapper {
  justify-content: end;
}

.dicom_preview{
  margin-bottom: 10px;
}

.conclusion{
  padding: 0;
  margin: 0;
}

.studyCard {
  /* margin-bottom: 20px; */

  margin: auto;
  width: 70%;
  margin-bottom: 20px;
  /* padding: 32px; */
  border-top: 1px solid #32444d;
  border-radius: 8px;
  background-color: rgba(33, 43, 47, 0.8);
  color: #f1f4f6;
}

.tableCell {
  border-bottom: 1px solid #969292;
}

.progressBar {
  background: green;

  height:20px;
  border-radius:10px;
}

.pathologiesTable {
  /* margin-bottom: 20px; */
  
  margin: auto;
  width: 70%;
  margin-bottom: 20px;
  padding: 32px;
  border-top: 1px solid #32444d;
  border-radius: 8px;
  background-color: rgba(33, 43, 47, 0.8);
  color: #f1f4f6;
}

.measurementsTable {
  /* margin-bottom: 20px; */
  
  margin: auto;
  width: 70%;
  margin-bottom: 20px;
  padding: 32px;
  border-top: 1px solid #32444d;
  border-radius: 8px;
  background-color: rgba(33, 43, 47, 0.8);
  color: #f1f4f6;
}

.downloadPDFLink {
  color: #ffffff;
  text-decoration: none;
}

.frameImage {
  max-width: 50%;
  padding: 10px 10px;
}

.tableRow {
  cursor: pointer;
}
