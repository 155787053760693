.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}

.inner {
  transform: translate(-50%, -50%);
}
