a {
  text-decoration: none;
}

.mainNav {
  height: 64px;
  width: 100%;
  display: flex;
  padding: 0 20px;
  position: fixed;
  z-index: 2;
  background: transparent;
  transition: 0.2s;
  border-bottom: 1px solid #212b2f;
}

.menuOnScroll {
  background: rgba(13, 26, 36, 0.97);
  transition: 0.2s;
}

.navLinksList {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
  padding: 0;
}

.logo {
  width: 120px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  padding-right: 40px;
}

.headerSection {
  width: 100%;
  background-size: cover;
}

.navLink {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 10px;
}

.navLinksListItem {
  padding: 10px 10px;
  margin-right: 10px;
  transition: 0.2s;
  border-radius: 5px;
}

.navLinksListItem:hover {
  background: rgba(50, 68, 77, 0.5);
  transition: 0.2s;
}

.contactButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loginIcon {
  margin-right: 5px;
}

.loginButton,
.footerLoginButton {
  color: #ffffff;
  margin-left: 15px;
  padding: 10px;
  font-size: 14px;
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  height: 45px;
  text-transform: capitalize;
  border-radius: 10px;
  width: 125px;
}

.loginButton:hover {
  color: #ffffff;
  margin-left: 15px;
  padding: 10px;
  font-size: 14px;
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  height: 45px;
  text-transform: capitalize;
  border-radius: 10px;
  background: rgba(50, 68, 77, 0.5);
}

.contactButton {
  background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
  border-radius: 6px;
  margin-left: 0;
  color: #ffffff;
  width: 125px;
}

.contactButtonWrapper {
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  border-radius: 10px;
  height: 45px;
  padding: 2px;
}

.blueBg {
  color: #0f87ff;
}

.signupButton {
  background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
  text-transform: none;
  width: 200px;
  padding: 10px 0;
  color: #ffffff;
}

.contactUsButton {
  width: 100%;
  background: #ffffff;
  color: #09a4ed;
  font-weight: 700;
  letter-spacing: 1px;
  height: 45px;
}

.contactUsButton:hover {
  background-color: rgba(246, 249, 250, 0.85);
}

.menuIcon {
  color: #ffffff;
  display: none;
}

.desktopScreenButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 992px) {
  .menuIcon {
    display: block;
  }

  .navLinksList {
    display: none;
  }

  .desktopScreenButtons {
    display: none;
  }
}
