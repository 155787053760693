.passwordCustomInput {
  display: flex;
  align-items: flex-end;
}

.inputLabel {
  color: #bacbd2;
  font-size: 12px;
}

.formControl {
  margin-bottom: 25px;
}

.deleteIcon {
  font-size: 22px;
}

.manageTokenButton {
  padding: 0;
  justify-content: end;
  min-width: 40px;
  color: #ffffff;
}

.container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.form {
  width: 100%;
}

.webhookContainer{
  background-color: rgba(33, 43, 47, 0.8);
  color: #f1f4f6;
}