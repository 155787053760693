.icon {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  position: relative;
  min-height: 100px;
  width: 100%;
}

.noDataText {
  position: absolute;
  top: 70%;
  transform: translate(-50%, -50%);
  left: 50%;
}
