.navLink {
  color: #ffffff;
  text-decoration: none;
  margin-right: 20px;
}

.accountIcon {
  color: #ffffff;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
}

.accountIconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
  width: 140px;
}

.logoWrapper {
  width: 140px;
}

.mainNavWrapper {
  max-width: 900px;
  width: 100%;
}

.popoverList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.popoverListItem {
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.logoutIcon {
  margin-right: 10px;
}

.logo {
  width: 140px;
  padding-top: 5px;
}

.drawerHeader {
  justify-content: space-between;
}

.pageName {
  padding-top: 10px;
}

.toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color:black
}

.navLink {
  color: #ffffff;
}
