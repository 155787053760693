.emptyHooksWrapper {
  margin: 0 auto;
  display: block;
  text-align: center;
  color: rgb(184, 183, 183);
  font-weight: 500;
}

.folderIcon {
  font-size: 150px;
}

.createButtonWrapper {
  display: flex;
}

.controlButtons {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.singleExportButtonWrapper {
  justify-content: flex-end;
}

.controlButton {
  margin-right: 10px;
  background: #1976d2;
}

.addIcon {
  font-size: 18px;
  margin-right: 5px;
}

.editButton {
  color: #ffffff;
  margin-right: 5px;
  height: 37px;
}

.tableCell {
  border-bottom: 1px solid #969292;
}

.preloaderContainer {
  position: relative;
  height: 80px;
}

.createWebhookInput {
  margin-right: 10px;
  margin-left: 10px;
  min-width: 250px;
}

.webhookTable {
  /* padding: 32px; */
  border-top: 1px solid #32444d;
  border-radius: 8px;
  background-color: rgba(33, 43, 47, 0.8);
  color: #f1f4f6;
}