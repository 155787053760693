.wrapper {
    /*max-width: 900px;*/
    width: 90%;
    margin: 0 auto;

    padding: 32px;
    border-top: 1px solid #32444d;
    border-radius: 8px;
    background-color: rgba(33, 43, 47, 0.8);
    color: #f1f4f6;

}

.listItem {
    padding: 10px 15px;
    /* border: 1px solid #fff; */
    border-radius: 10px;
    margin-right: 20px;
    /*width: 250px;*/
    color: #fff;
    text-decoration: none;

    background-color: #32444d;

    margin-bottom: 24px;

    a {
        color: #f1f4f6;
    }
}

.listItem:last-child {
    margin-right: 0;
}

.mainHeader {
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}

.searchWrapper {
    text-align: center;
}

.searchInput {
    padding: 4px;
    border: 1px solid #b0b0b0;
    border-radius: 4px;
    width: 300px;
    background: #ffffff;
    color: black;
}

.searchIcon {
    color: #000000;
}

.createButton {
    /* border: 1px dashed #ffffff; */
    color: #ffffff;
    padding: 15px;
    /* text-transform: capitalize; */

    margin-right: 0px;
    border-radius: 3px;
    background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
    -webkit-transition: all 300ms ease, box-shadow 200ms ease, background-color 200ms ease;
    transition: all 300ms ease, box-shadow 200ms ease, background-color 200ms ease;
    font-family: Pulpdisplay, sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding: 6px 12px;
    text-transform: none;
}

.studiesList {
    margin-top: 50px;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.listItemHeader {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.listItemDate {
    font-size: 14px;
    margin-bottom: 5px;
}

.listItemId {
    font-size: 14px;
}

.studiesWrapper {
    margin-bottom: 24px;
    min-height: 180px;
}

.paginationWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.showItems {
    font-size: 14px;
}
