a {
  text-decoration: none;
}

.navLinksList {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
}

.contactButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loginIcon {
  margin-right: 5px;
}

.loginButton,
.footerLoginButton {
  color: #ffffff;
  margin-left: 15px;
  padding: 10px;
  font-size: 14px;
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  height: 45px;
  text-transform: capitalize;
  border-radius: 10px;
  width: 125px;
}

.loginButton:hover {
  color: #ffffff;
  margin-left: 15px;
  padding: 10px;
  font-size: 14px;
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  height: 45px;
  text-transform: capitalize;
  border-radius: 10px;
  background: rgba(50, 68, 77, 0.5);
}

.contactButton {
  background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
  border-radius: 6px;
  margin-left: 0;
  color: #ffffff;
  width: 125px;
}

.contactButtonWrapper {
  border: 2px solid;
  border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
  border-radius: 10px;
  height: 45px;
  padding: 2px;
}

.encephalon,
.sloganGradient {
  background-image: linear-gradient(270deg, #01ffff, #09a4ed);
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blueBg {
  color: #0f87ff;
}

.signupButton {
  background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
  text-transform: none;
  width: 200px;
  padding: 10px 0;
  color: #ffffff;
}

.footer {
  width: 100%;
  padding-top: 100px;
  border-top: 1px solid #32444d;
  background-color: rgb(25, 32, 35);
}

.footerContainer {
  margin: 0 auto 100px auto;
}

.footerLogo {
  width: 150px;
}

.footerSlogan {
  color: #99b2bf;
  font-weight: 700;
  font-size: 24px;
}

.footerSlogan {
  margin-bottom: 35px;
}

.gradientLine {
  width: 100%;
  height: 2px;
  background-image: linear-gradient(270deg, #01ffff, #09a4ed);
}

.footerHeader {
  margin-bottom: 50px;
}

.footerNavContainer {
  padding-right: 40px;
}

.footerNavHeader {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 1px solid #32444d;
}

.footerNavHeaderItem {
  padding-left: 10px;
}

.footerNav {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.footerNavList {
  padding: 0;
  width: 100%;
}

.footerNavListItem {
  color: #99b2bf;
  list-style: none;
  padding: 10px 0 10px 10px;
  margin-bottom: 15px;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.2s;
  display: flex;
  align-items: center;
}

.footerNavListItem:hover {
  background-color: #32444d;
  transition: 0.2s;
}

.linkedinIcon {
  margin-right: 5px;
}

.contactCardHeader {
  margin: 0 0 15px 0;
}

.contactCard {
  padding: 20px;
  background-image: linear-gradient(220deg, #00d4d4, #0f87ff);
  box-shadow: -8px 0 32px 0 rgba(22, 152, 233, 0.2), 8px 0 32px 0 rgba(7, 200, 164, 0.2);
  border-radius: 5px;
  color: #ffffff;
  margin-bottom: 20px;
}

.contactCardText {
  color: rgba(246, 249, 250, 0.75);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
}

.contactUsButton {
  width: 100%;
  background: #ffffff;
  color: #09a4ed;
  font-weight: 700;
  letter-spacing: 1px;
  height: 45px;
}

.contactUsButton:hover {
  background-color: rgba(246, 249, 250, 0.85);
}

.footerLoginWrapper {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  align-items: center;
  font-weight: 600;
}

.footerLoginButton {
  margin-left: 0;
  width: 140px;
}

.footerLoginDivider {
  margin-bottom: 0;
}

.footerNavWrapper {
  padding-bottom: 40px;
  border-bottom: 1px solid #32444d;
  margin-bottom: 10px;
}

.copyrights {
  color: #7e929e;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 900px) {
  .footerLoginButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .footerLoginWrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footerLoginDivider {
    margin-bottom: 15px;
  }
}


