.canvasControls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.canvasButton {
  margin: 0 5px;
  background: #0f87ff;
}

.blockHeader {
  margin-bottom: 15px;
}

.selectLabel {
  padding-right: 15px;
}

.formControl {
  width: 180px;
  margin-right: 5px;
}

.formControl:last-child {
  margin-right: 0;
}

.dicomsSubmit {
  background: #0f87ff;
  height: 56px;
}

.filtersWrapper {
  margin-bottom: 20px;
}

.selectedOption {
  border: 1px solid;
}

.wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
